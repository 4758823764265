import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useTreatments } from '@splitsoftware/splitio-react';

import { useByPartnerCustomerIdMembership } from 'src/api/useByPartnerCustomerIdMembership';
import { Split, Treatment } from 'src/constants/Split';
import LoadingContainer from 'src/components/LoadingContainer';
import { useAuthContext } from 'src/components/AuthProvider';

const ConsentLayout: React.FC = ({ children }) => {
  const { loading: authLoading, userId } = useAuthContext();
  const treatments = useTreatments([Split.featureConsentToMarket]);
  const { data, loading } = useByPartnerCustomerIdMembership();

  useEffect(() => {
    if (loading) return;

    if (
      treatments[Split.featureConsentToMarket].treatment === Treatment.Off ||
      (data?.associatedAgent?.allowedToSell && !data?.marketingConsent?.needsEmailMarketingConsent)
    ) {
      navigate(`/checkout/information?userId=${userId}`);
    }

    if (!data?.associatedAgent?.allowedToSell && !data?.marketingConsent?.needsEmailMarketingConsent) {
      // Immediately send user to this route if conditions are met - /consent/captured should be after user submits consent form
      navigate('/state-farm/consent/unauthorized');
    }
  }, [loading]);

  if (loading || authLoading)
    return (
      <div className="state-farm-loading">
        <LoadingContainer />
      </div>
    );

  return <>{children}</>;
};

export { ConsentLayout };
