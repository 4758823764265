import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import smoothscroll from 'smoothscroll-polyfill';

import Container from 'src/components/Container';
import { GlobalSiteHeader } from 'src/components/GlobalSiteHeader';
import isBrowser from 'src/helpers/isBrowser';
import removePrefix from 'src/helpers/removePrefix';
import { Path } from 'src/constants/Route';
import ensureTailingSlash from 'src/helpers/ensureTailingSlash';
import 'src/styles/styles.scss';

import { StateFarmLayout } from './state-farm';

// kick off the polyfill!
isBrowser && smoothscroll.polyfill();

type Props = {
  children: JSX.Element | JSX.Element[];
  pageContext: Record<string, any>;
  location: Location;
  title: string;
  path: string;
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
};

const LayoutContainer: React.FC<Props> = ({ children, location, title, data, pageContext, path }) => {
  const pathname = removePrefix(location.pathname);
  const pageClass =
    pathname === '/'
      ? 'page__index'
      : // convert / to _ and remove trailing
        `page_${pathname.replace(/\//g, '_').replace(/_$/, '')}`;
  const isCancellationPath = ensureTailingSlash(pathname) === Path.MyAccountCancellation;

  return (
    <Container>
      {/* tuck standard helmet here so we get it by magic with layout */}
      <Helmet title={title ? title : get(data, 'site.siteMetadata.title')}>
        <html lang="en" data-release={process.env.BUILD_VERSION} />
        <link rel="canonical" href={`${process.env.HAGERTY_ROOT_URL}${location.pathname}`} />
        <meta name="robots" content="none" />
        <body className={pageClass} />
      </Helmet>
      {!isCancellationPath && <GlobalSiteHeader location={location} />}

      {pageContext.layout === 'state-farm' && <StateFarmLayout path={path}>{children}</StateFarmLayout>}

      {!pageContext.layout && children}
    </Container>
  );
};

export default function Template(props) {
  return (
    <StaticQuery
      query={graphql`
        query TemplateQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => <LayoutContainer data={data} {...props} />}
    />
  );
}
