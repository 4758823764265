import getFeatureFlagOverride from './src/helpers/getFeatureFlagOverride';
import getAECID from './src/helpers/getAECID';

const adobeExperienceCloudId = getAECID();
const key = getFeatureFlagOverride() || adobeExperienceCloudId || 'anonymous';
// Split.io SDK settings for browser.
export default {
  core: {
    authorizationKey: process.env.SPLIT_AUTHORIZATION_KEY,
    key,
  },
};
