import React, { useEffect, useContext } from 'react';
import isBrowser from 'src/helpers/isBrowser';
import { Context } from 'src/models/context';
import { useMfaLogin } from 'src/hooks/use-mfa-login';
import { isReady } from 'src/components/SplitContext/isReady';

// Conditionally import from browser or server side code.
const useAuthentication = isBrowser ? require('./browser').default : require('./server').default;

export const useRequireAuthenticated = (afterLoginUrl?: string) => {
  const { loading, isAuthenticated } = useAuthentication();
  const login = useMfaLogin();
  const splitIsReady = isReady();

  useEffect(() => {
    if (loading || isAuthenticated || !splitIsReady) {
      return;
    }
    login({ redirect_uri: afterLoginUrl });
  }, [loading, isAuthenticated, splitIsReady]);
};

export const AuthenticationContext = React.createContext<Partial<Context>>({ loading: true, isAuthenticated: false });

type Props = {
  children: React.ReactChildren;
};

export const AuthenticationProvider: React.FC<Props> = ({ children }) => {
  const value = useAuthentication();
  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};

export const useAuthContext = () => useContext(AuthenticationContext);
