import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ensureTailingSlash from 'src/helpers/ensureTailingSlash';
import getLinkVariables from 'src/helpers/linkVariables';
import removePrefix from 'src/helpers/removePrefix';
import slugify from 'src/helpers/slugify';
import useMembership from 'src/api/useMembership';
import { isReady } from './SplitContext';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Split, Treatment } from 'src/constants/Split';

type MenuItem = {
  id: string;
  label: string;
  link?: [string, string, string];
  active?: boolean;
  children?: MenuItem[];
};

export type Menu = MenuItem[];

const parseLink = (text: string): [string, string, string] => {
  const local = window.location.host;

  if (local == 'localhost:8000') {
    if (text.endsWith('/')) {
      text = text.substr(0, text.length - 1);
    }

    const lastItem = text.substring(text.lastIndexOf('/') + 1);

    const link = `http://${local}/${lastItem}`.split('|').map((s) => s.trim());
    if (link.length === 1) {
      return [link[0], link[0], link[0]];
    }

    if (link.length === 2) {
      return [link[0], link[1], link[1]];
    }
  }
  const link = text.split('|').map((s) => s.trim());
  if (link.length === 1) {
    return [link[0], link[0], link[0]];
  }

  if (link.length === 2) {
    return [link[0], link[1], link[1]];
  }
  return [link[0], link[0], link[0]];
};

const parseSubnavLink = (path: string): [string, string, string] => {
  const paths = path.split('|').map((s) => s.trim());
  const anonPath = paths[0];
  const loggedInPath = paths.length === 2 ? paths[1] : paths[0];
  return [anonPath, loggedInPath, loggedInPath];
};

export const parseMenu = (markdown: string): Menu => {
  const lines = markdown
    .replace(/<!--[\n.]*-->\n/, '')
    .match(/[^\r\n]+/g)
    .map((line) => line.trimRight())
    .filter((line) => line.length > 0);
  const menus: MenuItem[] = [];
  const pattern = /^(\s*)- \[(.+)]\((.+)\)$/;

  const append = (level: number, item: MenuItem) => {
    let p = menus;
    while (level > 0) {
      --level;
      const item = p[p.length - 1];
      if (!item.children) {
        item.children = [];
        item.active = true;
      }
      p = item.children;
    }
    p.push(item);
  };

  for (const line of lines) {
    const m = line.match(pattern);
    if (!m) continue;
    const level = m[1].length / 2;
    const label = m[2];
    const path = m[3];
    const isSubNavLink = level === 1;

    append(level, { id: slugify(label), label, link: isSubNavLink ? parseSubnavLink(path) : parseLink(m[3]) });
  }
  return menus;
};

const useSiteHeaderMenu = (location: Location) => {
  const { t, ready } = useTranslation();
  const [items, setItems] = useState<MenuItem[]>([]);
  const { data: membership, hasLegacyMembership } = useMembership();
  const splitIsReady = isReady();

  const treatments = useTreatments([Split.FeatureBenefitsPage]);

  const hasBenefitsPage = treatments[Split.FeatureBenefitsPage].treatment === Treatment.On;

  const hasMembership = membership?.hasMembership() || hasLegacyMembership;
  const siteHeaderMenus =
    hasMembership || !hasBenefitsPage
      ? 'site-header.internal-subnav.menus'
      : 'site-header.internal-subnav.menus.redemption';

  const page = ensureTailingSlash(removePrefix(location.pathname));

  useEffect(() => {
    const markdown = t([`site-header.menus.${page}`, siteHeaderMenus], getLinkVariables());
    setItems(parseMenu(markdown));
  }, [ready, membership, splitIsReady]);

  return items;
};

export default useSiteHeaderMenu;
