import { withPrefix } from 'gatsby';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { cachePersistor } from 'src/api/client';
import isBrowser from 'src/helpers/isBrowser';
import getCookieValue from 'src/helpers/getCookieValue';

const complete = (pathname) => (isBrowser ? `${window.location.origin}${withPrefix(pathname)}` : '');

const nullStorage: Storage = {
  length: 0,
  getItem: () => null,
  setItem: () => undefined,
  removeItem: () => undefined,
  clear: () => undefined,
  key: (): string => null,
};

const settings = {
  authority: `${process.env.OIDC_ISSUER}`,
  client_id: `${process.env.OIDC_CLIENT_ID}`,
  // IS3 requires client_secret to be set for authorization code + pkce. oidc-client will only
  // send the client_secret if it is a non-empty value. Check to be sure it is not undefined
  // so we don't set it to 'undefined' by accident.
  // If IS4 is well behaved and no longer requires this, we can remove the client_secret setting
  // altogether.
  client_secret:
    process.env.HDC_FLAG_OIDC_ISSUER_IS3 === 'true' && process.env.OIDC_CLIENT_SECRET !== undefined
      ? `${process.env.OIDC_CLIENT_SECRET}`
      : '',
  redirect_uri: complete(`/callback`),
  silent_redirect_uri: complete(`/callback`),
  post_logout_redirect_uri: complete('/'),
  response_type: 'code',
  scope: 'openid profile email AgentIdentifiers BrokerRep',
  automaticSilentRenew: true,
  metadata: {
    issuer: `${process.env.OIDC_ISSUER}`,
    authorization_endpoint: `${process.env.OIDC_ISSUER}/connect/authorize`,
    token_endpoint: `${process.env.OIDC_ISSUER}/connect/token`,
    userinfo_endpoint: `${process.env.OIDC_ISSUER}/connect/userinfo`,
    end_session_endpoint: `${process.env.OIDC_ISSUER}/connect/endsession`,
  },
  userStore: new WebStorageStateStore({ store: isBrowser ? window.localStorage : nullStorage }),
};

const userManager = new UserManager(settings);

userManager.events.addUserLoaded(function (user) {
  console.log('UserLoaded:', user.access_token);
  cachePersistor.purge();
  userManager.clearStaleState();
  userManager.getUser();

  if (isBrowser) {
    const ACCESS_TOKEN = 'accessToken';
    const accessTokenCookie = getCookieValue(ACCESS_TOKEN);
    const nextAuthCookie = getCookieValue('next-auth.session-token');
    if (!accessTokenCookie && !nextAuthCookie) {
      document.cookie = `${ACCESS_TOKEN}=${user?.access_token}; path=/; expires=Thu, 01 Jan 2200 00:00:00 GMT`;
    }
  }
});

userManager.events.addUserUnloaded(function () {
  userManager.clearStaleState();
  cachePersistor.purge();
});

userManager.events.addSilentRenewError(function (e) {
  userManager.clearStaleState();
  cachePersistor.purge();
  console.error('silent renew error', e.message);
});

userManager.events.addAccessTokenExpired(function () {
  userManager.clearStaleState();
  cachePersistor.purge();
  console.error('access token expired');
});

export default userManager;
