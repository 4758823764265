import { useEffect, useState } from 'react';
import { useTreatments } from '@splitsoftware/splitio-react';

import { stfrmCustomer, stfrmAgent, stfrm } from 'src/constants/agent';
import { Split, Treatment } from '../constants/Split';

import useProfile from 'src/api/useProfile';
import { useAuthContext } from 'src/components/AuthProvider';

export const useShelbyCustomer = () => {
  const { idp, partnerLogin } = useAuthContext();
  const [banner, setBanner] = useState(null);
  const { data: profile, loading: profileLoading } = useProfile();

  const isStfrmCustomer =
    idp === stfrmCustomer ||
    partnerLogin?.partnerId === stfrmCustomer ||
    (profile?.brokers?.companyId === stfrm && idp !== stfrmAgent);
  const isShelbyCustomer = isStfrmCustomer || idp === stfrmAgent;
  const treatments = useTreatments([Split.FeatureStfCustomerStandalonePurchase]);
  const hasStfCustomerStandalonePurchase =
    treatments[Split.FeatureStfCustomerStandalonePurchase].treatment === Treatment.On;

  useEffect(() => {
    if (profileLoading) return;
    if (isStfrmCustomer && !hasStfCustomerStandalonePurchase) {
      setBanner({
        display: true,
        message: 'checkout.banner.shelbyCustomer',
        color: 'failure',
      });
    } else {
      setBanner(null);
    }
  }, [isStfrmCustomer, hasStfCustomerStandalonePurchase, profileLoading]);

  return {
    banner,
    setBanner,
    idp,
    isStfrmCustomer,
    isShelbyCustomer,
    hasStfCustomerStandalonePurchase,
  };
};
