import { useEffect, useState } from 'react';

import useProfile from 'src/api/useProfile';
import isBrowser from 'src/helpers/isBrowser';
import digestMessage from 'src/helpers/digestMessage';

export const useDigestEmail = () => {
  const [hashHex, setHashHex] = useState('');
  const { data: profile } = useProfile();

  useEffect(() => {
    if (isBrowser && profile) {
      digestMessage(profile.email).then((hex) => setHashHex(hex));
    }
  }, [profile]);

  return { hashHex };
};
