import React, { useEffect, useState } from 'react';
import { SplitFactory, SplitClient } from '@splitsoftware/splitio-react';

import { SplitContext } from './SplitContext';
import { SplitProviderProps } from './types';

import getUniqueUser from 'src/helpers/getUniqueUser';

export const SplitProvider: React.FC<SplitProviderProps> = ({ config: splitConfig, children, ...x }) => {
  const [userId, setUserId] = useState(splitConfig.core.key);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userId === 'anonymous') {
      getUniqueUser().then((data) => {
        setUserId(data);
        splitConfig.core.key = data;
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <SplitContext.Provider value={{ loading }}>
      <SplitFactory config={splitConfig} {...x}>
        <SplitClient splitKey={userId}>{children}</SplitClient>
      </SplitFactory>
    </SplitContext.Provider>
  );
};
