import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const LoadingThrobber = ({ height = 32, width = 32 }) => {
  function t(t, i) {
    for (var n in i) t.setAttribute(n, i[n]);
  }

  function i(t) {
    (this.element = t),
      (this.rect = [
        this.element.querySelectorAll('*')[0],
        this.element.querySelectorAll('*')[1],
        this.element.querySelectorAll('*')[2],
      ]),
      this.animationId,
      (this.loop = 0),
      (this.start = null),
      this.init();
  }

  useEffect(() => {
    if (!window.requestAnimationFrame) {
      var n = null;
      window.requestAnimationFrame = function (t) {
        var e = new Date().getTime();
        n || (n = e);
        var o = Math.max(0, 16 - (e - n)),
          r = window.setTimeout(function () {
            t(e + o);
          }, o);
        return (n = e + o), r;
      };
    }
    (i.prototype.init = function () {
      var t = this;
      (this.loop = 0), (this.animationId = window.requestAnimationFrame(t.triggerAnimation.bind(t)));
    }),
      (i.prototype.reset = function () {
        var t = this;
        window.cancelAnimationFrame(t.animationId);
      }),
      (i.prototype.triggerAnimation = function (i) {
        var n = this;
        this.start || (this.start = i);
        var e = i - this.start,
          o = [],
          r = [],
          a = 1 - (2 * e) / 1e3,
          s = (2 * e * 16) / 1e3,
          l = 0.4 + (2 * e) / 1e3,
          m = 16 * (0.6 - (2 * e) / 1e3);
        this.loop % 2 == 0
          ? ((o[0] = a), (r[0] = s), (o[1] = o[2] = l), (r[1] = r[2] = m))
          : ((o[0] = l), (r[0] = m), (o[1] = o[2] = a), (r[1] = r[2] = s)),
          300 > e || ((this.start = this.start + 300), (this.loop = this.loop + 1));
        for (var c = 0; 3 > c; c++)
          t(this.rect[c], {
            transform: 'translate(0 ' + r[c] + ') scale(1 ' + o[c] + ')',
            style: 'opacity:' + o[c] + ';',
          });
        if (document.documentElement.contains(this.element)) window.requestAnimationFrame(n.triggerAnimation.bind(n));
      });

    var e = document.getElementsByClassName('nc-loop_bars-32'),
      o = [];
    if (e)
      for (var r = 0; e.length > r; r++)
        !(function (t) {
          o.push(new i(e[t]));
        })(r);

    document.addEventListener('visibilitychange', function () {
      'hidden' == document.visibilityState
        ? o.forEach(function (t) {
            t.reset();
          })
        : o.forEach(function (t) {
            t.init();
          });
    });
  }, []);

  return (
    <svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <title>loading</title>
      <g fill="#0089c7" stroke="#0089c7" strokeLinecap="square" strokeWidth="2">
        <g className="nc-loop_bars-32">
          <line
            style={{ opacity: '0.8926940000000176' }}
            fill="none"
            transform="translate(0 1.7168959999997169) scale(1 0.8926940000000176)"
            x1="16"
            x2="16"
            y1="7"
            y2="24"
          />
          <line
            style={{ opacity: '0.5073059999999823' }}
            fill="none"
            stroke="#0089c7"
            transform="translate(0 7.883104000000283) scale(1 0.5073059999999823)"
            x1="3"
            x2="3"
            y1="7"
            y2="24"
          />
          <line
            style={{ opacity: '0.5073059999999823' }}
            fill="none"
            stroke="#0089c7"
            transform="translate(0 7.883104000000283) scale(1 0.5073059999999823)"
            x1="29"
            x2="29"
            y1="7"
            y2="24"
          />
        </g>
      </g>
    </svg>
  );
};
LoadingThrobber.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default LoadingThrobber;
