import { useEffect, useState } from 'react';

import { useAuthContext } from 'src/components/AuthProvider';
import { useDigestEmail } from './useDigestEmail';
import useMembership from 'src/api/useMembership';
import { isAgent } from 'src/api/client';
import isBrowser from 'src/helpers/isBrowser';
import digestMessage from 'src/helpers/digestMessage';

export interface UserTracker {
  logged_in: boolean; // boolean identifying logged in status
  hashed_email?: string; // string lower cased, sha256 encrypted email
  hdc_status?: string; // string 'active' if hdc member
  hdc_membership?: string; // string hdc tier of product if member
}

export const useUserTracker = (): UserTracker => {
  const [user, setUser] = useState(null);
  const { isAuthenticated, loading: authLoading, brokers, userId, idp } = useAuthContext();
  const { data: membership, loading: membershipLoading, partnerCustomerId } = useMembership();
  const { hashHex } = useDigestEmail();

  useEffect(() => {
    const ready = !membershipLoading && !authLoading;
    const hasMembership = membership?.hasMembership();
    const stfrmReferral = (idp && idp === 'stfrm-agent') || !!partnerCustomerId ? true : '';

    if (!ready) return;

    if (hasMembership && isAuthenticated && hashHex) {
      setUser({
        logged_in: isAuthenticated,
        hashed_email: hashHex,
        hdc_status: 'active',
        hdc_membership: membership?.data?.name ?? null,
        state_farm_referral: stfrmReferral,
      });
    }

    if (!hasMembership && isAuthenticated && hashHex) {
      setUser({
        logged_in: isAuthenticated,
        hashed_email: hashHex,
        hdc_status: 'inactive',
        hdc_membership: null,
        state_farm_referral: stfrmReferral,
      });
    }

    if (!isAuthenticated) {
      setUser({
        logged_in: false,
        hdc_status: 'inactive',
      });
    }

    // Used for agent portal - is not state farm checkout flow with userId
    if (isAgent && !userId && isAuthenticated && isBrowser) {
      digestMessage(brokers?.agentEmail).then((hex) => {
        setUser({
          logged_in: isAuthenticated,
          hashed_email: hex,
          state_farm_referral: stfrmReferral,
        });
      });
    }
  }, [isAuthenticated, membershipLoading, authLoading, membership, hashHex]);

  return user;
};
