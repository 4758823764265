import { useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';

import { isAgent } from './client';
import { ByPartnerCustomerIdMember } from './types';
import useProfile from 'src/api/useProfile';

import { HdcMembershipStatus } from 'src/constants/HdcMembershipStatus';
import { useAuthContext } from 'src/components/AuthProvider';
import ByPartnerCustomerIdQuery from './graphql/users/queries/ByPartnerCustomerIdQuery.graphql';

const useByPartnerCustomerIdMembership = (options?: QueryHookOptions) => {
  const { data: profile, loading: profileLoading } = useProfile();
  const { isAuthenticated, loading: authLoading } = useAuthContext();

  const queryOptions: QueryHookOptions = {
    skip: !isAgent || !isAuthenticated || !profile || profileLoading,
    variables: {
      status: HdcMembershipStatus.ACTIVE,
      partnerCustomerId: profile?.partnerCustomerId,
    },
    ...options,
  };

  const { data, loading } = useQuery<ByPartnerCustomerIdMember>(ByPartnerCustomerIdQuery, queryOptions);
  const dataWithAgentData = {
    ...data?.member?.byPartnerCustomerId,
    lastBridgingBroker: profile?.lastBridgingBroker,
  };
  return { data: dataWithAgentData, loading: loading || profileLoading || authLoading };
};

export { useByPartnerCustomerIdMembership };
