import { useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { useMemo } from 'react';
import { isAgent } from 'src/api/client';
import { Inventory, Product } from 'src/api/types';
import { useProducts } from 'src/api/useInventories';
import getMembershipProductAlias from 'src/helpers/getMembershipProductAlias';
import MileagesQuery from './graphql/users/queries/MileagesQuery.graphql';

import type { ActiveProduct, Mileage, Money } from 'src/models/types';

export const toMileage = (inventory: Inventory): Mileage => {
  if (!inventory) return null;
  const { id, level, name, skuCode: sku, priceCents, benefits } = inventory;
  const { towingDistance, vehiclesCovered, amountCovered } = benefits.roadside;
  const price: Money = {
    ...priceCents,
    amount: priceCents.amount / 100,
  };
  return { id, level, name, sku, price, towingDistance, amountCovered, vehiclesCovered };
};

export const toProduct = (product): ActiveProduct => {
  if (!product) return null;

  const { inventories, id, name, description } = product;

  const mileages: Mileage[] = inventories?.map(toMileage) || [];

  mileages.sort((a, b) => a.level - b.level);

  const basePrice: Money = { ...mileages[0].price };

  for (const mileage of mileages) {
    mileage.price.amount -= basePrice.amount;
  }

  return {
    id,
    name,
    description,
    basePrice,
    mileages,
  };
};

// Get the product that contains active mileages for sale.
const useUsersActiveProductFromAlias = () => {
  const alias = getMembershipProductAlias();
  const options: QueryHookOptions = {
    errorPolicy: 'none', // Authenticated endpoint will raise an unhandled error
    fetchPolicy: 'network-only', // We won't retrieve these details from the cache
    variables: {
      alias,
    },
  };

  const { loading, error, data } = useQuery(MileagesQuery, options);
  const r = data?.product;

  const product = useMemo(() => toProduct(r), [r]);

  return {
    loading,
    error,
    product,
  };
};

const isBrokerProductForUS = (p: Product) => {
  if (!p.brokers?.enable) return false;
  if (p.inventories[0].priceCents.currency !== 'USD') return false;

  return p.inventories.findIndex((inv) => inv.skuCode === process.env.PRODUCT_GEAR_1_SKU) !== -1;
};

const useBrokersActiveProduct = () => {
  const { loading, error, data } = useProducts();
  const product = useMemo(() => {
    const p = data?.products?.data?.find(isBrokerProductForUS);
    if (p) {
      return toProduct(p);
    }
    return null;
  }, [data?.products]);

  return {
    loading,
    error,
    product,
  };
};

const useActiveProduct = () => {
  const hook = isAgent ? useBrokersActiveProduct : useUsersActiveProductFromAlias;
  return hook();
};

export default useActiveProduct;
