import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'src/locales/en-US/resource.json';
import fr from 'src/locales/fr-CA/resource.json';
import dateFormat from 'date-fns/format'

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
    format: function(value, format) {
      if (format === 'uppercase') return value.toUpperCase();
      if(value instanceof Date) return dateFormat(value, format);
      return value;
    }
  },
});

export default i18n;
