import isBrowser from 'src/helpers/isBrowser'

const getDriversClubUrl = () => {
  if (!isBrowser) {
    return 'https://www.hagerty.com/drivers-club';
  }

  const host = window.location.host;
  if (host.includes('localhost')) return '//' + host;
  if (host.includes('driversclub.')) return '//' + host;
  return '//' + host + '/drivers-club';
};


type Environment = 'www' | 'mo' | 'qa' | 'dev'

const environment = (): Environment => {
  const m = process.env.HAGERTY_ROOT_URL.match(/^https:\/\/([^.]+)\.hagerty\.com$/)
  return m[1] as Environment
}

const getLinkVariables = () => ({
  env: environment(),
  hagerty: process.env.HAGERTY_ROOT_URL,
  driversClub: getDriversClubUrl(),
});

export default getLinkVariables;
