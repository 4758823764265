import { useTreatments } from '@splitsoftware/splitio-react';
import { Split, Treatment } from 'src/constants/Split';
import { login as authLogin } from 'src/components/AuthProvider/browser';

const useMfaLogin = () => {
  const treatments = useTreatments([Split.FeatureHdcMfa]);
  const hasMfa = treatments[Split.FeatureHdcMfa].treatment === Treatment.On;

  const login = (params: any = {}) => {
    /**
     * Sending acr_values: 'aal1' to Identity bypasses Multifactor Authentication
     * page: https://qalogin.hagerty.com/identity/Mfa/Verify when logging in only.
     * It does not bypass the Create Account - email confirmation / verify page:
     * https://qalogin.hagerty.com/identity/CreateAccount/Verify
     */
    if (params?.acr_values && hasMfa) {
      params.acr_values = params.acr_values.concat(' ', 'aal1');
    } else if (hasMfa) {
      params.acr_values = 'aal1';
    }

    return authLogin(params);
  };

  return login;
};

export { useMfaLogin };
