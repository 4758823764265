import qs from 'qs';
import isBrowser from 'src/helpers/isBrowser';

const useQueryAgent = () => {
  if (!isBrowser) {
    return {};
  }

  const getQueryParam = (key: string) => qs.parse(location.search, { ignoreQueryPrefix: true })?.[key] ?? '';
  // todo read values from token
  return {
    companyId: getQueryParam('company'),
    agentId: getQueryParam('agent'),
    bridgeAgentId: getQueryParam('bridge'),
    agencyId: getQueryParam('agency'),
    userId: getQueryParam('userId'),
    cpid: getQueryParam('CPID'),
  };
};

export default useQueryAgent;
