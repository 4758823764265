import isBrowser from './isBrowser';

const getFeatureFlagOverride = (): string | null => {
  let storageValue = null;

  if (isBrowser && window.sessionStorage) {
    storageValue = sessionStorage.getItem('feature_flag_override');
  }

  return storageValue;
};

export default getFeatureFlagOverride;
