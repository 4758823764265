import { useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { DocumentNode } from 'graphql';
import { useEffect, useState } from 'react';
import { useTreatments } from '@splitsoftware/splitio-react';

import { isAgent, memberGraphMetaData } from 'src/api/client';
import { AmsMembershipAsset, Inventory, Membership, Subscription } from 'src/api/types';
import { useCustomerMember } from './useCustomerMember';

import UsersMembershipQuery from 'src/api/graphql/users/queries/MembershipQuery.graphql';
import { useAuthContext } from 'src/components/AuthProvider';
import useInventories, { InventoryCollection } from './useInventories';
import { Split, Treatment } from 'src/constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';
import useProfile from 'src/api/useProfile';

type UsersApiMembershipPayload = {
  membership: {
    data: Membership;
  };
  log?: {
    omniResponseTime: number;
    omniResponseID: string;
  };
};

const LEGACY_PRODUCT_TYPE = 'HDC 1.0';
const LEGACY_TEST_DRIVE_ASSET_NAME = 'Test Drive';
enum OmniProductCode {
  USMEMBHDC1,
  USMEMBHDC2,
  USMEMBHDC3,
  USFREETRIAL,
  USMEMBHDCVIP,
}

export class MembershipAccess {
  public readonly data: Membership;
  private readonly hasLegacy: boolean;

  constructor(membership?: Membership) {
    this.data = membership;
  }

  get id(): string {
    return this.data?.id;
  }

  /**
   * Detects if the user has a valid membership
   */
  hasMembership(): boolean {
    return this.data?.status === 'active';
  }

  /**
   * Detects if there is a membership object for the user
   */
  hasMembershipObject(): boolean {
    return Boolean(this.data);
  }

  /**
   * Detects if the membership is VIP
   */
  isVip(): boolean {
    return this.isOneYearVip() || this.isLifetimeVip();
  }

  /**
   * Detects if the membership is one year VIP
   */
  isOneYearVip(): boolean {
    return this.hasMembership() && this.data?.skuCode?.startsWith(process.env.PRODUCT_ONE_YEAR_VIP_SKU);
  }

  /**
   * Detects if the membership is lifetime VIP
   */
  isLifetimeVip(): boolean {
    return this.hasMembership() && this.data?.skuCode?.startsWith(process.env.PRODUCT_LIFETIME_VIP_SKU);
  }

  /**
   * Detects if the membership will be canceled at the end of the current period
   */
  willBeCanceled() {
    return this.hasMembership() && (!this.data.subscription || this.data.subscription?.status === 'canceled');
  }

  // TODO - Remove with ProductRadioSelect component as this component and subsequent pages are no longer used
  /**
   * Detects if current user has active membership subscription
   */
  hasActiveSubscription(): boolean {
    return this.data?.subscription?.status === 'active';
  }

  // TODO - Remove with AccountSections component as downgrade is no longer needed
  /**
   * Detects if the membership will be downgraded at the end of the current period
   */
  willBeDowngraded(): boolean {
    return this.level > this.data?.subscription?.context?.level && this.data.subscription.status === 'active';
  }

  expired(): boolean {
    return !!this.data && this.data.status === 'expired';
  }

  wasCanceled(): boolean {
    return !!this.data && this.data.status === 'canceled';
  }

  expiredLast30Days(): boolean {
    if (!this.hasMembershipObject()) return false;
    const today = new Date();
    const thirtyDaysAgo = new Date().setDate(today.getDate() - 30);
    const expiryDate = new Date(this.data.expireAt).valueOf();
    return this.data.status === 'expired' && expiryDate > thirtyDaysAgo;
  }

  expireIn30Days(): boolean {
    if (!this.hasMembership()) return false;
    const today = new Date();
    const expireAt = new Date(this.data.expireAt);
    expireAt.setDate(expireAt.getDate() - 30);
    return +today > +expireAt;
  }

  get inventory(): Inventory {
    return this.data?.inventory;
  }

  get nextInventory(): Inventory {
    return this.data?.subscription?.context?.inventory;
  }

  get level(): number {
    return this.data?.level;
  }

  get effectiveAt(): Date {
    return this.data?.effectiveAt ? new Date(this.data?.effectiveAt) : null;
  }

  get expireAt(): Date {
    return this.data?.expireAt ? new Date(this.data?.expireAt) : null;
  }
}

const normalizeMembership = (
  membership: Membership,
  subscription: Subscription,
  inventories: InventoryCollection
): Membership => {
  if (!membership) return undefined;
  if (!inventories) return membership;

  const cp = {
    ...membership,
    inventory: inventories.byId[membership.inventoryId],
  };

  if (subscription) {
    cp.subscription = {
      ...subscription,
      context: {
        ...subscription.context,
        inventory: inventories.byId[membership.subscription?.context?.inventoryId],
      },
    };
  }

  return cp;
};

const normalizeCustomerData = (data: AmsMembershipAsset, hdcNumber: string) => {
  const normalizedData = {
    membership: {
      data: {
        hdcNumber: hdcNumber,
        currency: data.product.price.currency,
        effectiveAt: data.effectiveAt,
        expireAt: data.expireAt,
        id: data.omniMembershipId,
        inventoryId: data.product.omniInventoryId,
        level: data.product.level,
        orderId: data.omniOrderId,
        price: data.product.price.amount,
        productId: data.product.id,
        name: data.product.name,
        skuCode: data.product.skuCode,
        status: data.status.toLowerCase(),
        subscription: null,
        roadsideBenefit: data.product.benefits[0], // include for useBenefitsPageQueries until hdc fully switches to use member graph
      },
    },
  };

  if (data.subscriptionStatus) {
    normalizedData.membership.data.subscription = {};
    normalizedData.membership.data.subscription.status = data.subscriptionStatus;
  }

  return normalizedData;
};

// For membership log
const postMembershipLog = async (payload) => {
  memberGraphMetaData.hasLogged = true;
  try {
    await fetch(`${process.env.BFF_URL}/membershiplog`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  } catch (error) {
    console.error(error);
  }
};

function useMembershipQuery(doc: DocumentNode, options: QueryHookOptions<any, any>, inventories: InventoryCollection) {
  const [membership, setMembership] = useState<MembershipAccess>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [membershipData, setMembershipData] = useState(null);
  const [membershipQuery, setMembershipQuery] = useState(null);
  const [hasLegacyMembership, setHasLegacyMembership] = useState(false);
  const [calledOnce, setCalledOnce] = useState(false);
  const treatments = useTreatments([Split.FeaturePreventDuplicateMemberships]);
  const splitIsReady = isReady();

  const hasOmniTreatment = treatments[Split.FeaturePreventDuplicateMemberships].treatment === Treatment.Omni;
  const hasMemberGraphTreatment =
    treatments[Split.FeaturePreventDuplicateMemberships].treatment === Treatment.MemberGraph;
  const hasBothOmniTreatment = treatments[Split.FeaturePreventDuplicateMemberships].treatment === Treatment.BothOmni;
  const hasBothMemberGraphTreatment =
    treatments[Split.FeaturePreventDuplicateMemberships].treatment === Treatment.BothMemberGraph;

  const skipOptions = options.skip;
  // skip Member Graph call if treatment is Omni
  const { data: customerData, ...customerQuery } = useCustomerMember({
    skip: skipOptions || !splitIsReady || hasOmniTreatment,
  });
  // override member skip options - skip Omni call if treatment is MemberGraph
  options.skip = skipOptions || !splitIsReady || hasMemberGraphTreatment;
  const { data, ...query } = useQuery<UsersApiMembershipPayload>(doc, options);

  // For membership log
  const { data: profile } = useProfile();
  const [secondMembershipQueryForLog, setSecondMembershipQueryForLog] = useState(null);
  useEffect(() => {
    if (
      (hasBothOmniTreatment || hasBothMemberGraphTreatment) &&
      data &&
      customerData &&
      profile &&
      !memberGraphMetaData.hasLogged
    ) {
      const payload = {
        omniMembershipID: profile?.membershipId,
        memberGraphMembershipID: customerData?.member.member.id,
        omniResponseTime: data?.log?.omniResponseTime,
        omniResponseID: data?.log?.omniResponseID,
        memberGraphResponseTime: customerData?.log?.memberGraphResponseTime,
        memberGraphResponseID: customerData?.log?.memberGraphResponseId,
        omniOrderList: Object.keys(data?.membership.data).length === 0 ? [] : [data?.membership.data],
        memberGraphAssetList: customerData?.member.member.assets,
      };
      postMembershipLog(payload);
    }
  }, [data, customerData, profile, memberGraphMetaData]);

  useEffect(() => {
    if (!splitIsReady) return;

    if (hasMemberGraphTreatment || hasBothMemberGraphTreatment) {
      if (customerData) {
        let normalizedData;
        if (customerData?.member?.member?.assets?.length) {
          normalizedData = normalizeCustomerData(customerData?.member.member.assets[0], customerData?.member.member.id);
          // If member graph product code does not match Omni product codes, assume membership is HDC 1.0
          const hasMatchingProductCode = (Object.keys(OmniProductCode) as (keyof typeof OmniProductCode)[]).some(
            (key) =>
              customerData.member.member.assets.length && key === customerData.member.member.assets[0].product.code
          );
          setHasLegacyMembership(customerData.member.member.assets[0]?.product?.code && !hasMatchingProductCode);
        } else {
          // after membership is canceled or expires, customer member graph returns empty assets array
          normalizedData = {
            membership: {
              data: null,
            },
          };
        }
        setMembershipData(normalizedData);
      }
      setMembershipQuery(customerQuery); // set query to return loading state
    } else if (hasOmniTreatment || hasBothOmniTreatment) {
      if (data) {
        setMembershipData(data);
      }
      setMembershipQuery(query); // set query to return loading state

      // Check Omni /profile/me memberhsip product type for HDC 1.0 membership
      const assets = profile?.assets || [];
      const hasMatchingProductType = assets.some(
        (a) => a.type === LEGACY_PRODUCT_TYPE && a.name !== LEGACY_TEST_DRIVE_ASSET_NAME
      );
      setHasLegacyMembership(hasMatchingProductType);
    }
    // For membership log
    if (hasBothOmniTreatment) {
      setSecondMembershipQueryForLog(customerQuery);
    } else if (hasBothMemberGraphTreatment) {
      setSecondMembershipQueryForLog(query);
    }
  }, [splitIsReady, data, customerData, profile]);

  useEffect(() => {
    if (splitIsReady && membershipData && membershipQuery?.loading === false) {
      const mem: Membership = membershipData?.membership?.data;
      const sub: Subscription = mem?.subscription;
      setMembership(new MembershipAccess(normalizeMembership(mem, sub, inventories)));
      setIsLoading(false);
    } else if (splitIsReady && membershipQuery?.loading === false) {
      setIsLoading(false);
    }
  }, [splitIsReady, inventories, membershipQuery, membershipData]);

  useEffect(() => {
    if (!splitIsReady || hasOmniTreatment || hasBothOmniTreatment) return;
    /**
     * Refetch Customer Member Graph query after cancelling membership
     * only for customers with no refund / not immediate cancellation
     */
    if (
      membershipQuery &&
      membership &&
      membership.willBeCanceled() &&
      membership.data?.subscription?.status !== 'canceled' &&
      !calledOnce
    ) {
      membershipQuery.refetch();
      setCalledOnce(true);
    }
  }, [membershipQuery, membership, splitIsReady]);

  return {
    ...membershipQuery,
    data: membership,
    loading: isLoading,
    hasLegacyMembership,
    secondMembershipQueryForLog,
    partnerCustomerId: customerData?.member?.member?.partnerCustomer?.id,
  };
}

const useMembership = (options?: QueryHookOptions) => {
  const { isAuthenticated, loading: loadingUser } = useAuthContext();
  const { data: inventories } = useInventories({ fetchPolicy: 'network-only' });
  const queryOptions: QueryHookOptions<any, any> = {
    skip: isAgent || !isAuthenticated || loadingUser,
    errorPolicy: 'ignore',
    ...options,
  };
  return !isAgent && useMembershipQuery(UsersMembershipQuery, queryOptions, inventories);
};

export default useMembership;
