import React from 'react';
import DigiCertIcon from 'src/components/icons/digiCert.svg';

const DigiCertLogo = () => (
  <div className="digi-cert-logo">
    <span className="visibly-hidden">DigiCert Secure</span>
    <DigiCertIcon />
  </div>
);

export default DigiCertLogo;
