/**
 * Remove path prefix from pathname.
 *
 * @param pathname {string}
 */
const removePrefix = (pathname) => {
  const prefix = '/drivers-club/';
  if (pathname.startsWith(prefix)) {
    return pathname.substr(prefix.length - 1);
  }
  return pathname;
};

export default removePrefix;
