import React from 'react';
import StateFarmIcon from 'src/components/icons/stateFarm.svg';

type Props = {
  className?: string;
};
const StateFarmLogo: React.FC<Props> = ({ className = '' }) => (
  <div className={`${className} state-farm-logo`} data-cy="stfrm-logo">
    <span>for </span>
    <span className="visibly-hidden">State Farm</span>
    <StateFarmIcon />
  </div>
);

export default StateFarmLogo;
