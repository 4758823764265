import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { useMemo } from 'react';
import useMembership, { MembershipAccess } from 'src/api/useMembership';
import { Profile, Roadside, AmsRoadsideBenefit } from 'src/api/types';
import { toMileage } from 'src/api/useActiveProduct';
import useProfile from 'src/api/useProfile';
import { useAuthContext } from 'src/components/AuthProvider';
import { Split, Treatment } from 'src/constants/Split';
import { useTreatments } from '@splitsoftware/splitio-react';

export type Membership = {
  name: string;
  renewalAt?: string;
  mileage: Roadside;
  hdcNumber?: string;
};

type BenefitsPageData = {
  ready: boolean;
  isAuthenticated: boolean;
  profile: Profile;
  membership?: Membership;
  hasLegacyMembership: boolean;
};

const parseDate = (s: string): Date => {
  if (!s) return null;
  try {
    return parse(s, 'yyyy-MM-dd', new Date());
  } catch (_) {
    return null;
  }
};

const formatDate = (date: Date): string => {
  if (!date) return null;
  return format(date, 'MMMM d, yyyy');
};

const normalizeOmniLegacyRoadside = (raw: Roadside): Roadside => {
  if (!raw) return null;
  return {
    amountCovered: raw.amountCovered && raw.amountCovered * 100,
    towingDistance: raw.towingDistance,
    vehiclesCovered: raw.vehiclesCovered.toLowerCase(),
  } as Roadside;
};

const normalizeMemberGraphRoadside = (raw: AmsRoadsideBenefit): Roadside => {
  if (!raw) return null;
  return {
    amountCovered: raw.amountCovered,
    towingDistance: raw.towingDistance,
    vehiclesCovered: raw.vehicleType,
  } as Roadside;
};

const populateMembership = (
  membership: MembershipAccess,
  hasLegacyMembership: boolean,
  profile: Profile,
  hasMemberGraphTreatment: boolean,
  hasMemberGraphData: boolean
): Membership => {
  const isMember = Boolean(membership?.hasMembership() || hasLegacyMembership);

  if (!isMember) {
    return null;
  }

  const isV15 = membership?.hasMembership();

  const name = isV15 ? membership.inventory?.name : profile.assets?.[0]?.name?.replace(/^HDC /, '');
  const renewalAt = formatDate(
    isV15 || hasMemberGraphData ? membership.expireAt : parseDate(profile.assets?.[0].expiresAt)
  );

  // Omni 1.0 legacy membership is found in API /profile/me `assets` array - returns amountCovered in dollar value
  const omniMileage = hasLegacyMembership
    ? normalizeOmniLegacyRoadside(profile?.assets?.[0]?.roadside)
    : toMileage(membership.inventory);

  // All member types from member graph will return amountCovered in cent value
  const mileage = hasMemberGraphTreatment
    ? normalizeMemberGraphRoadside(membership?.data?.roadsideBenefit)
    : omniMileage;

  const hdcNumber = hasMemberGraphTreatment && hasMemberGraphData ? membership?.data?.hdcNumber : profile?.membershipId;

  return {
    name,
    renewalAt,
    mileage,
    hdcNumber,
  };
};

const useBenefitsPageQueries = (): BenefitsPageData => {
  const { isAuthenticated, loading: userLoading } = useAuthContext();
  const { data: membership, loading: membershipLoading, hasLegacyMembership } = useMembership();
  const { data: profile, loading: profileLoading } = useProfile();
  const treatments = useTreatments([Split.FeaturePreventDuplicateMemberships]);
  const hasMemberGraphTreatment =
    treatments[Split.FeaturePreventDuplicateMemberships].treatment === Treatment.MemberGraph ||
    treatments[Split.FeaturePreventDuplicateMemberships].treatment === Treatment.BothMemberGraph;
  const hasMemberGraphData = !!membership?.data?.roadsideBenefit;

  return useMemo((): BenefitsPageData => {
    const ready = !(membershipLoading || profileLoading || userLoading);

    return {
      ready,
      isAuthenticated,
      profile,
      membership: populateMembership(
        membership,
        hasLegacyMembership,
        profile,
        hasMemberGraphTreatment,
        hasMemberGraphData
      ),
      hasLegacyMembership,
    };
  }, [userLoading, isAuthenticated, membershipLoading, profileLoading, membership, profile]);
};

export default useBenefitsPageQueries;
