import { useQuery } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { useMemo } from 'react';
import type { Inventory, Product } from 'src/api/types';
import { isAgent } from 'src/api/client';
import { useAuthContext } from 'src/components/AuthProvider'

import BROKERS from './graphql/brokers/queries/ProductsQuery.graphql';
import USERS from './graphql/users/queries/ProductsQuery.graphql';

export type ProductsPayload = {
  products: {
    total: number;
    data: Product[];
  };
};

export type InventoryCollection = {
  bySku: Record<string, Inventory>;
  byId: Record<string, Inventory>;
  all: Inventory[];
};

const normalizeInventories = (products: Product[]): InventoryCollection => {
  const bySku: Record<string, Inventory> = {};
  const byId: Record<string, Inventory> = {};
  const all: Inventory[] = [];

  products?.forEach((p) => {
    p.inventories?.forEach((inventory) => {
      all.push(inventory);
      bySku[inventory.skuCode] = inventory;
      byId[inventory.id] = inventory;
    });
  });

  return { byId, bySku, all };
};

type RefetchResult<T> = {
  data: T;
  loading: boolean;
  error?: ApolloError;
};

export type QueryResult<T> = {
  data: T;
  loading: boolean;
  error?: ApolloError;
  refetch: () => Promise<RefetchResult<T>>;
};

export const useProducts = (options?: QueryHookOptions<ProductsPayload>): QueryResult<ProductsPayload> => {
  const { isAuthenticated } = useAuthContext();
  const query = isAgent ? BROKERS : USERS;
  const { loading, error, data, refetch } = useQuery<ProductsPayload>(query, { ...options, skip: !isAuthenticated});
  return { loading, error, data, refetch };
}

const useInventories = (options?: QueryHookOptions<ProductsPayload>): QueryResult<InventoryCollection> => {
  const { loading, error, data, refetch } = useProducts(options);
  const raw = data?.products?.data;
  const inventories = useMemo(() => normalizeInventories(raw), [raw]);
  const refetchNormalised = async (): Promise<RefetchResult<InventoryCollection>> => {
    const result = await refetch();

    const data = normalizeInventories(result.data?.products.data);
    return { loading: result.loading, error: result.error, data };
  };
  return { loading, error, data: inventories, refetch: refetchNormalised };
};

export default useInventories;
