import isBrowser from 'src/helpers/isBrowser';

// For a list of supported alias please see the follow link:
// https://dev.azure.com/hagerty/Hagerty/_git/wiredcraft-scripts?path=%2Fmisc%2Fsrc%2Fscript%2Fproduct.js&version=GBmaster&_a=contents
const getMembershipProductAlias = () => {
  if (isBrowser) {
    const params = new URLSearchParams(window.location.search);
    if (location.host !== 'www.hagerty.com' && location.host !== 'driversclub.hagerty.com' && params.get('p')) {
      return params.get('p');
    }
  }

  return 'hdc';
};

export default getMembershipProductAlias;
