import { isAgent } from 'src/api/client';
import { stfrm, stfrmAgent } from 'src/constants/agent';

const getCompanyId = (idp: string) => (idp === stfrmAgent ? stfrm : '');
const getUserFromLocalStorage = () => {
  const userKey =
    Object.keys(localStorage).filter((propertyName) => propertyName.indexOf('oidc.user:') === 0)[0] ?? null;
  const userFromStorage = localStorage.getItem(userKey);
  const user = JSON.parse(userFromStorage);
  return user;
};

const getAgentHeders = (user) => {
  const companyId = getCompanyId(user.profile.idp);
  if (isAgent) {
    return {
      'x-company-id': companyId,
      'x-agency-id': user.profile.AgencyID,
      'x-agent-id': user.profile.AgentID,
      'x-bridge-agent-id': user.profile.BridgeAgentID,
    };
  }
  return {};
};

const setHeaders = async (api = 'omni-api') => {
  const user = getUserFromLocalStorage();
  if (!user) {
    return {};
  }

  const companyId = getCompanyId(user.profile.idp);
  if (api === 'terms-api') {
    return {
      'x-brokers-company-id': companyId,
      'x-brokers-agency-id': user.profile.AgencyID,
      'x-brokers-agent-id': user.profile.AgentID,
      'x-brokers-bridge-agent-id': user.profile.BridgeAgentID,
      'x-brokers-federation-id': user.profile.sub,
      'x-agent-email': user.profile.email,
      'x-brokers-legacy-account-id': user.profile.BrokerAccountLegacyKey,
      'x-brokers-rep-key': user.profile.BrokerRepPK,
    };
  }

  return {
    Authorization: `Bearer ${user.access_token}`,
    'X-Access-Token': user.access_token,
    ...getAgentHeders(user),
  };
};

export default setHeaders;
export { getUserFromLocalStorage };
