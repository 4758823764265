import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  // The key in contentful resource.
  /**
   * The `string | string[]` needs to be removed for better type support
   * this will make the `resourceKey` more strictly typed since we know the values
   * from Contentful.
   */
  resourceKey: Contentful | Contentful[] | string | string[];

  // The options translates
  // Reference: https://www.i18next.com/translation-function/essentials#overview-options
  values?: Record<string, any>;

  // Use dangerouslySetInnerHTML instead of children to put the translated text.
  useHtml?: boolean;

  // The tag used to render the translation.
  // Can be custom component or html tags.
  // Default to `span`.
  as?: React.ComponentType | keyof JSX.IntrinsicElements;

  dynamicKeys?: string[];

  [x: string]: any;
};

export const Translate: React.FC<Props> = ({ as, resourceKey, values, dynamicKeys, useHtml, ...others }) => {
  const { t } = useTranslation();
  let translation = t(resourceKey, values);

  dynamicKeys?.map((key, index) => {
    translation = translation.replace(`{${index}}`, dynamicKeys[index]);
  });

  const options: Record<string, any> = {};
  if (useHtml) {
    options.dangerouslySetInnerHTML = { __html: translation };
  } else {
    options.children = translation;
  }

  const key = resourceKey instanceof Array ? resourceKey[0] : resourceKey;
  const As = as || 'span';

  return <As data-cy={key} data-i18n-key={key} {...others} {...options} />;
};
