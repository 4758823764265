import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import { isAgent } from 'src/api/client';
import { Translate } from 'src/components/Translate';
import StateFarmLogo from 'src/components/agents/StateFarmLogo';
import DigiCertLogo from 'src/components/agents/DigiCertLogo';
import { isReady } from 'src/components/SplitContext/isReady';
import LoadingContainer from 'src/components/LoadingContainer';

import { ConsentLayout } from './consent';
import './layout.scss';

type Props = {
  path: string;
};

type StateFarmLayouts = 'consent' | 'terms-and-conditions';

/**
 * Change `as const` to `satisfies Record<string, Contentful>`
 * Once we update the projects typescript to 4.9 and eslint packages.
 *
 * Using this will give better typescript support.
 */
const contentful = {
  copyRight: 'footer.copyrightLabel',
  privacyPolicy: 'footer.footerNavigation.privacyPolicy',
  siteHelp: 'footer.footerNavigation.siteHelp',
} as const;

const useStateFarmRoutes = (path: string) => {
  const [layout, setLayout] = useState<StateFarmLayouts>();

  useEffect(() => {
    if (path.match(/consent/)) setLayout('consent');

    if (path.match(/terms-and-conditions/)) setLayout('terms-and-conditions');
  }, [path]);

  return layout;
};

const StateFarmLayout: React.FC<Props> = ({ children, path }) => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const splitReady = isReady();
  const layout = useStateFarmRoutes(path);

  useEffect(() => {
    if (splitReady) setIsPageLoaded(true);
  }, [splitReady]);

  useEffect(() => {
    if (!isAgent) navigate('/');
  }, [isPageLoaded]);

  if (!isPageLoaded)
    return (
      <div className="state-farm-loading">
        <LoadingContainer />
      </div>
    );

  return (
    <>
      <div className="container">
        {layout === 'consent' && <ConsentLayout>{children}</ConsentLayout>}

        {!layout && children}

        <div className="inset-l" />

        {layout !== 'terms-and-conditions' && (
          <Translate className="disclaimer state-farm-container" resourceKey="state-farm.disclaimer.footer" as="p" />
        )}
      </div>

      <footer className="state-farm-footer container">
        <div>
          <StateFarmLogo />

          <div className="footer-bottom">
            <Translate resourceKey={contentful.copyRight} as="span" />

            <nav>
              <ul className="nav-items">
                <li>
                  <a href="https://www.hagerty.com/corporate/privacy-policy">
                    <Translate resourceKey={contentful.privacyPolicy} />
                  </a>
                </li>
                <li id="cobrowse-link">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.stopPropagation();
                      return false;
                    }}
                  >
                    <Translate resourceKey={contentful.siteHelp} />
                  </a>
                </li>
              </ul>

              <DigiCertLogo />
            </nav>
          </div>
        </div>
      </footer>
    </>
  );
};

export { StateFarmLayout };
