import isBrowser from 'src/helpers/isBrowser';

const noop = () => {};

const initTracker = () => {
  if (process.env.TEALIUM_ENV === 'local' || !isBrowser) {
    return { trackEvent: noop, trackPageLoad: noop };
  } else {
    const initTealiumTracker = require('tealium-tracker').default;
    return initTealiumTracker({ debugMode: process.env.TEALIUM_ENV === 'dev' || process.env.TEALIUM_ENV === 'qa' });
  }
};

const tracker = initTracker();

export const trackEvent = (args) => {
  if (process.env.TEALIUM_ENV === 'local' && isBrowser) {
    console.log('Tracking Event With Args:', args);
  }

  tracker.trackEvent(args);
};
export const trackPageLoad = (args) => {
  if (process.env.TEALIUM_ENV === 'local' && isBrowser) {
    console.log('Tracking Page Load With Args:', args);
  }

  tracker.trackPageLoad(args);
};
