/**
 * gets a simple hash for the inputted string
 * @param {string} str The string to be hashed
 */
const getSimpleHash = (str) => {
  var hash = 0;
  if (str.length == 0) {
    return hash;
  }
  for (var i = 0; i < str.length; i++) {
    var char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

export default getSimpleHash;