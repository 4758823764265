export const flags = [
  {
    name: 'OIDC_RELAX',
    isActive: process.env.HDC_FLAG_OIDC_RELAX === 'true',
  },
  {
    name: 'OIDC_ISSUER_IS3',
    isActive: process.env.HDC_FLAG_OIDC_ISSUER_IS3 === 'true',
  },
  {
    name: 'OIDC_ISSUER_AUTH0',
    isActive: process.env.HDC_FLAG_OIDC_ISSUER_AUTH0 === 'true',
  },
];
