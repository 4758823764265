import isBrowser from './isBrowser';
import getSimpleHash from 'src/helpers/getSimpleHash';
import { v4 as uuidv4 } from 'uuid';

const getUniqueUser = async () => {
  const userId = 'userId';
  let uniqueIdentifier = 'anonymous';

  if (isBrowser && window.localStorage) {
    const storageValue = localStorage.getItem(userId);
    if (storageValue) {
      return storageValue;
    } else {
      uniqueIdentifier = await fetch("https://api.ipify.org/?format=json")
        .then((results) => results.json())
        .then((data) => {
          return 'userId-' + getSimpleHash(data.ip);
        })
        .catch(error => {
          return uuidv4();
        });
      localStorage.setItem(userId, uniqueIdentifier);
      return uniqueIdentifier;
    }
  }

  return uniqueIdentifier;
};

export default getUniqueUser;
