import { ApolloProvider } from '@apollo/client';
import React from 'react';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { Headers as ServerHeaders } from 'node-fetch';
import { FlagsProvider } from 'react-feature-flags';

import 'src/components/i18n';
import client from 'src/api/client';
import { flags } from './feature-flags';
import { AuthenticationProvider } from './src/components/AuthProvider';
import { SplitProvider } from './src/components/SplitContext/SplitProvider';
import sdkConfig from './sdkConfig';
import { TrackingProvider } from './src/components/TrackingContext';

const clientBrowser = typeof document !== 'undefined';
global.Headers = clientBrowser ? global.Headers : ServerHeaders;

if (process.env.SENTRY_DSN && process.env.SENTRY_ENVIRONMENT !== 'local') {
  const option = {
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    release: process.env.SENTRY_RELEASE,
  };
  if (process.env.SENTRY_ENABLE_TRACING === 'true') {
    const defaultSampleRate = parseFloat(process.env.SENTRY_DEFAULT_SAMPLE_RATE);
    const samplers =
      process.env.SENTRY_TRACES_SAMPLERS &&
      process.env.SENTRY_TRACES_SAMPLERS.split(';').map((i) => ({
        pattern: new RegExp(i.split(',')[0]),
        rate: parseFloat(i.split(',')[1]),
      }));
    const tracingOrigins = (process.env.SENTRY_TRACING_ORIGINS && process.env.SENTRY_TRACING_ORIGINS.split(',')) || [];
    option.integrations = [
      new Integrations.BrowserTracing({
        beforeNavigate: (context) => {
          return {
            ...context,
            name: location.pathname.replace(/[a-fA-F0-9,-]{8,36}/g, ':id'),
          };
        },
        tracingOrigins,
      }),
    ];
    option.tracesSampler = (samplingContext) => {
      const sampler = samplers && samplers.find((s) => s.pattern.test(samplingContext.transactionContext.name));
      if (sampler) {
        return sampler.rate;
      } else {
        return defaultSampleRate;
      }
    };
  }
  Sentry.init(option);
}

/**
 * Helper function to enable/disable tealium
 */
window.traceTealium = (enable) => {
  if (enable) {
    localStorage.setItem('hdc:utag.link', 'log');
    console.log('Trace Tealium enabled');
  } else {
    localStorage.removeItem('hdc:utag.link');
    console.log('Trace Tealium disabled');
  }
};

export function wrapRootElement({ element }) {
  console.log('Hagerty Drivers Club 1.5');
  return React.createElement(
    ApolloProvider,
    { client },
    React.createElement(
      FlagsProvider,
      { value: flags },
      React.createElement(
        SplitProvider,
        { config: sdkConfig, updateOnSdkTimedout: true },
        React.createElement(AuthenticationProvider, null, React.createElement(TrackingProvider, null, element))
      )
    )
  );
}

export const onRouteUpdate = ({ location: { pathname } }) => {
  return null;
};
