import { useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';

import { CustomerMember } from './types';
import { ServiceName } from './client';

import { useAuthContext } from 'src/components/AuthProvider';
import CustomerMemberQuery from './graphql/users/queries/CustomerMemberQuery.graphql';

const useCustomerMember = (options?: QueryHookOptions) => {
  const { isAuthenticated } = useAuthContext();
  const queryOptions: QueryHookOptions = {
    context: { serviceName: ServiceName.CustomerMember },
    errorPolicy: 'all',
    skip: !isAuthenticated,
    ...options,
  };

  const { data, ...query } = useQuery<CustomerMember>(CustomerMemberQuery, queryOptions);
  return { data, ...query };
};

export { useCustomerMember };
