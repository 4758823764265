import React from 'react';
import PropTypes from 'prop-types';

const VisaCard = ({ height = 24, width = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      data-cy="visa-card"
    >
      <path
        d="M0 1.927C0 0.863 0.892 0 1.992 0H38.008C39.108 0 40 0.863 40 1.927V22.073C40 23.137 39.108 24 38.008 24H1.992C0.892 24 0 23.137 0 22.073V1.927Z"
        fill="white"
      />
      <path
        d="M0 22.033C0 23.12 0.892 24 1.992 24H38.008C39.108 24 40 23.12 40 22.033V20.08H0V22.033Z"
        fill="#F8B600"
      />
      <path
        d="M0 3.92H40V1.967C40 0.88 39.108 0 38.008 0H1.992C0.892 0 0 0.88 0 1.967V3.92ZM19.596 7.885L17.486 17.363H14.93L17.04 7.885H19.594H19.596ZM30.339 14.005L31.682 10.445L32.455 14.005H30.34H30.339ZM33.189 17.363H35.549L33.486 7.885H31.31C30.818 7.885 30.405 8.159 30.222 8.58L26.39 17.363H29.072L29.604 15.948H32.88L33.19 17.363H33.189ZM26.522 14.269C26.532 11.767 22.922 11.629 22.945 10.509C22.953 10.171 23.29 9.809 24.028 9.716C24.393 9.671 25.401 9.636 26.545 10.141L26.993 8.131C26.378 7.917 25.588 7.711 24.603 7.711C22.08 7.711 20.303 8.999 20.29 10.844C20.274 12.208 21.558 12.969 22.524 13.424C23.52 13.888 23.854 14.186 23.849 14.601C23.843 15.237 23.056 15.519 22.323 15.529C21.038 15.549 20.293 15.196 19.7 14.929L19.238 17.009C19.836 17.271 20.938 17.499 22.078 17.511C24.76 17.511 26.515 16.238 26.523 14.268L26.522 14.269ZM15.948 7.884L11.81 17.362H9.11L7.076 9.8C6.953 9.334 6.846 9.163 6.47 8.966C5.855 8.646 4.84 8.346 3.95 8.16L4.01 7.885H8.355C8.909 7.885 9.407 8.239 9.533 8.851L10.609 14.337L13.264 7.887H15.947L15.948 7.884Z"
        fill="#1A1F71"
      />
    </svg>
  );
};
VisaCard.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default VisaCard;
