import { Profile } from 'oidc-client';
import { roleField } from 'src/constants/brokersContext';
import { isPrincipalString } from 'src/constants/brokersContext';

const populateBrokersContext = (user: Profile) => {
  return {
    brokers: {
      companyId: user?.AlternateAgencyID ?? null,
      agencyId: user?.AgencyID ?? null,
      agentId: user?.AgentID ?? null,
      agentEmail: user?.email ?? null,
      bridgeAgentId: user?.BridgeAgentID ?? null,
      isPrincipal: user?.[roleField].includes(isPrincipalString),
    },
  };
};

export default populateBrokersContext;
