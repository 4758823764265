import React, { useState } from 'react';
import { TrackingContext } from './TrackingContext';
import {
  TrackActionFn,
  TrackInteractionFn,
  TrackJourneyCompleteFn,
  TrackJourneyStartFn,
  TrackModalViewFn,
  TrackPopupCloseFn,
  TrackPopupFn,
  TrackViewFn,
} from 'src/components/TrackingContext/types';

export const TrackingProvider: React.FC = ({ children }) => {
  const [trackView, setTrackView] = useState<TrackViewFn>(null);
  const [trackInteraction, setTrackInteraction] = useState<TrackInteractionFn>(null);
  const [trackModalView, setTrackModalView] = useState<TrackModalViewFn>(null);
  const [trackAction, setTrackAction] = useState<TrackActionFn>(null);
  const [trackJourneyStart, setTrackJourneyStart] = useState<TrackJourneyStartFn>(null);
  const [trackJourneyComplete, setTrackJourneyComplete] = useState<TrackJourneyCompleteFn>(null);
  const [trackPopup, setTrackPopup] = useState<TrackPopupFn>(null);
  const [trackPopupClose, setTrackPopupClose] = useState<TrackPopupCloseFn>(null);

  const setTrackingFns = (
    trackViewFn: TrackViewFn,
    trackInteractionFn: TrackInteractionFn,
    trackModalViewFn: TrackModalViewFn,
    trackAction: TrackActionFn,
    trackJourneyStartFn: TrackJourneyStartFn,
    trackJourneyCompleteFn: TrackJourneyCompleteFn,
    trackPopupFn: TrackPopupFn,
    trackPopupCloseFn: TrackPopupCloseFn
  ) => {
    setTrackView(() => trackViewFn);
    setTrackInteraction(() => trackInteractionFn);
    setTrackModalView(() => trackModalViewFn);
    setTrackAction(() => trackAction);
    setTrackJourneyStart(() => trackJourneyStartFn);
    setTrackJourneyComplete(() => trackJourneyCompleteFn);
    setTrackPopup(() => trackPopupFn);
    setTrackPopupClose(() => trackPopupCloseFn);
  };

  return (
    <TrackingContext.Provider
      value={{
        trackView,
        trackInteraction,
        trackModalView,
        trackAction,
        trackJourneyStart,
        trackJourneyComplete,
        trackPopup,
        trackPopupClose,
        setTrackingFns,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};
