import React from 'react';
import PropTypes from 'prop-types';
import { LoadingThrobber } from 'src/components/atom/icons';

const LoadingContainer = ({ height }) => {
  return (
    <div className="container container_center page__my-account grid grid_align-center" data-cy="loading-container">
      <LoadingThrobber width={40} height={height} />
    </div>
  );
};

LoadingContainer.propTypes = {
  height: PropTypes.number,
};

export default LoadingContainer;
