import removePrefix from 'src/helpers/removePrefix';
import { navigate } from 'gatsby';
const handleRedirect = (state) => {
    const url = removePrefix(state && state.targetUrl ? state.targetUrl : window.location.pathname);
    state = {
      ...state,
      isRedirectCallback: true,
    };
  
    navigate(url, { replace: true, state: state });
};

export default handleRedirect;