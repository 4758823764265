import { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import useQueryAgent from 'src/hooks/useQueryAgent';
import authUtils from './authUtils';
import { useLocalStorage } from 'react-use';
import type { Context } from 'src/models/context';
import { isAgent } from 'src/api/client';
import populateBrokersContext from 'src/helpers/populateBrokersContext';
import { parsePartnerLogins } from 'src/helpers/parsePartnerLogins';
import parseErrorMessage from 'src/helpers/parseErrorMessage';
import handleRedirect from 'src/helpers/handleRedirect';
import Auth0ClientFacade from 'src/components/AuthProvider/auth0ClientFacade';
import userManager from './userManager';

const client = new Auth0ClientFacade(userManager);

/*
  Log.logger = console;
  Log.level = Log.DEBUG;
*/

export const login = async (params: any = {}) => {
  // set default target url to the page from which login was initiated.
  const { pathname, search } = window.location;

  const targetUrl = params?.redirect_uri || `${pathname}${search}`;
  const defaultState = { targetUrl };
  const state = Object.assign(defaultState, params?.state || {});
  const composedParams = Object.assign({}, params);
  // merge state separate since Object.assign doesn't do a recursive merge.
  composedParams.state = state;
  return client.loginWithRedirect(composedParams);
};
const logout = async () => {
  await client.logout();
};
const useAuthorization = (): Context => {
  const [value, setValue] = useState<Context>({
    loading: true,
    isAuthenticated: false,
    login: login,
    refresh: null,
    logout: logout,
  });
  const [shelbyUserID, setShelbyUserID] = useLocalStorage<string>('shelbyUserID');

  const setShelbyUserIDFromCpid = (userId: string) => {
    setShelbyUserID(userId);
    return setValue((prev) => ({
      ...prev,
      userId: userId,
    }));
  };

  const refresh = async (params: any = {}) => {
    const user = await authUtils.refreshLogin(params);
    const isAuthenticated = await authUtils.isAuthenticated();
    setValue((prev) => ({
      ...prev,
      isAuthenticated,
      loading: false,
      idp: user?.profile?.idp,
      profile: user?.profile,
      userId: shelbyUserID,
    }));
  };

  const initAuth0 = async (userId: string) => {
    if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
      try {
        const { appState } = await authUtils.handleRedirectCallback();
        handleRedirect(appState);
      } catch (e) {
        if (parseErrorMessage(e)?.includes('No matching state found in storage')) {
          console.log('Login again.');
          await refresh();
          return navigate('/my-account/');
        }
        throw e;
      }
    }

    let isAuthenticated = await authUtils.isAuthenticated();
    const localStorageCypressFlag = localStorage.getItem('CYPRESS_AGENT');

    if (isAgent && localStorageCypressFlag !== '1') {
      await authUtils.refreshSilently();
    }

    // Check user is authenticated after refresh
    isAuthenticated = await authUtils.isAuthenticated();
    const user = isAuthenticated ? await authUtils.getUser() : null;
    const brokers = isAgent && user ? populateBrokersContext(user) : {};
    setValue((prev) => ({
      ...prev,
      ...brokers,
      isAuthenticated,
      userId: userId ?? null,
      idp: user?.idp ?? null,
      loading: false,
      partnerLogin: parsePartnerLogins(user?.partner_logins),
      profile: user,
      setShelbyUserIDFromCpid,
    }));
  };

  useEffect(() => {
    const { userId: queryUserId } = useQueryAgent();
    // userid should be persisted
    const userId = (queryUserId ?? shelbyUserID) as string;

    if (isAgent && userId) {
      setShelbyUserID(userId);
    }

    setValue((prev) => ({ ...prev, refresh }));

    initAuth0(userId).then(null, console.error);
  }, []);
  return value;
};

export default useAuthorization;
