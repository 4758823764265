import React from 'react';
import PropTypes from 'prop-types';

const AmexCard = ({ height = 24, width = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      data-cy="amex-card"
    >
      <path
        d="M38.333 24H1.667C0.75 24 0 23.28 0 22.4V1.6C0 0.72 0.75 0 1.667 0H38.333C39.25 0 40 0.72 40 1.6V22.4C40 23.28 39.25 24 38.333 24Z"
        fill="white"
      />
      <path
        d="M6.25977 12.32H8.57277L7.41477 9.66L6.25977 12.32ZM27.3528 9.977H23.6148V11.207H27.2808V12.591H23.6058V13.976H27.4268V14.981C28.0498 14.211 28.7568 13.515 29.4518 12.746L30.1588 11.976C29.2248 10.972 28.2888 9.896 27.3548 8.901V9.978L27.3528 9.977Z"
        fill="#1478BE"
      />
      <path
        d="M38.25 7H32.645L31.317 8.4L30.072 7H16.984L15.967 9.416L14.877 7H5.297L1.25 16.5H6.076L6.699 14.944H8.099L8.722 16.5H29.99L31.317 15.017L32.645 16.5H38.25L33.89 11.833L38.25 7ZM20.565 15.1H19.008V9.883L16.673 15.1H15.343L13.01 9.883L12.926 15.1H9.73L9.107 13.544H5.837L5.132 15.1H3.42L6.304 8.328H8.724L11.369 14.561V8.33H14.015L16.122 12.84L17.99 8.33H20.565V15.1ZM35.292 15.1H33.268L31.244 12.84L29.221 15.1H22.06V8.328H29.53L31.325 10.505L33.349 8.328H35.374L32.26 11.75L35.292 15.1Z"
        fill="#1478BE"
      />
    </svg>
  );
};
AmexCard.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default AmexCard;
