import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { isAgent } from 'src/api/client';
import type { Profile } from 'src/api/types';
import { useAuthContext } from 'src/components/AuthProvider';
import BrokersProfileQuery from './graphql/brokers/queries/ProfileQuery.graphql';
import CpidProfileQuery from 'src/api/graphql/brokers/queries/CpidProfileQuery.graphql';
import UsersProfileQuery from './graphql/users/queries/ProfileQuery.graphql';
import useQueryAgent from 'src/hooks/useQueryAgent';

type ProfileQueryPayload = {
  profile: {
    data: Profile;
  };
};

const useProfile = (customOptions: QueryHookOptions = {}) => {
  const { isAuthenticated, loading: loadingUser, userId, setShelbyUserIDFromCpid } = useAuthContext();
  const { cpid } = useQueryAgent();

  const options: QueryHookOptions = {
    skip: !isAuthenticated || loadingUser,
    errorPolicy: 'none', // Authenticated endpoint will raise an unhandled error
    fetchPolicy: 'cache-first', // We can retrieve these details from the cache
    ...customOptions,
  };

  const query = isAgent ? (cpid ? CpidProfileQuery : BrokersProfileQuery) : UsersProfileQuery;

  if (isAgent) {
    options.variables = {
      ...options.variables,
      userId,
      cpid,
    };
  }
  const queryResult = useQuery<ProfileQueryPayload>(query, options);
  const data = cpid ? queryResult.data?.profile?.data[0] : queryResult.data?.profile?.data;

  useEffect(() => {
    if (cpid && data) {
      setShelbyUserIDFromCpid(data.id);
    }
  }, [data]);

  return { ...queryResult, data };
};

export default useProfile;
