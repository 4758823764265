import { useContext } from 'react';
import { useSplitContext } from './useSplitContext';
import { SplitContext as WrappedSplitContext } from '@splitsoftware/splitio-react';

export const isReady = () => {
  const { isReady: splitIsReady } = useContext(WrappedSplitContext);
  const { loading } = useSplitContext();

  return splitIsReady && !loading;
};

export const isTimedout = () => {
  const { isTimedout } = useContext(WrappedSplitContext);

  return isTimedout;
};
